export const getApiUrl = () => {
    let apiUrl = process.env.REACT_APP_API_URL || 'https://rubik-api.tatpnu.com';
    // let apiUrl = 'https://rubik-api.tatpnu.com';

    return apiUrl.replace(/\/$/, '');
};

export const HOST_API = process.env.REACT_APP_API_URL || 'https://rubik-api.tatpnu.com';

export const getQueryString = ( filterParams, page ) => {
    const validFilters = Object.fromEntries(
        Object.entries( filterParams ).filter( ( [ key, value ] ) => value !== '' )
    );

    return new URLSearchParams( { ...validFilters, page: page } ).toString();
};