import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import { Typography, Paper, Box, TextField, Button, Grid, Divider, DialogTitle,
    DialogContent, DialogContentText, DialogActions, Dialog, FormControl, Select, MenuItem
} from '@mui/material';

const UpdateAccountPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ accountData, setAccountData ] = useState( null );
    const [ isSubmitting, setIsSubmitting ] = useState( false );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    useEffect( () => {
        const fetchAccount = async () => {
            try {
                const response = await ( await fetch( `${getApiUrl()}/api/accounts/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                } ) ).json();

                setAccountData( response.data );
                setLoading( false );
            }
            catch ( err ) {
                setError(`Error fetching account details: ${err.message}`);
                setLoading( false );
            }
        };

        fetchAccount();
    }, [ id ] );

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
        navigate( `/accounts/${id}` );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( `/accounts/${id}/edit` );
    };

    const handleChange = ( e ) => {
        const { name, value } = e.target;

        setAccountData( ( prevData ) => ( {
            ...prevData,
            [ name ]: value,
        } ) );
    };

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await ( await fetch(`${getApiUrl()}/api/accounts/${id}`, {
                method: 'PUT',
                body: JSON.stringify( accountData ),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            } ) ).json();

            setTimeout( () => {
                setAccountData( response.data );
                setIsSuccessModalOpen( true );
                setIsSubmitting( false );
            }, 1000 );
        }
        catch ( err ) {
            setError( `${t('update_account_failed')}: ${err.message}` );
            setIsSubmitting( false );
            setIsErrorModalOpen( true );
        }
    };

    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    return (
        <>
            {/* Error Modal */}
            <Dialog
                open={isErrorModalOpen}
                onClose={handleCloseErrorModal}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{
                    style: { padding: '20px', borderRadius: '12px' },
                }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('error')}
                </DialogTitle>

                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {error ? error : t('unknown_error')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseErrorModal}
                        color="primary"
                        variant="contained"
                        style={{
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Modal */}
            <Dialog
                open={isSuccessModalOpen}
                onClose={handleCloseSuccessModal}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{
                    style: { padding: '20px', borderRadius: '12px' },
                }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('operation_result')}
                </DialogTitle>

                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('update_account_succeeded')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseSuccessModal}
                        color="primary"
                        variant="contained"
                        style={{
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper sx={{ p: 3 }}>
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                    <Typography variant="h5" fontWeight="bold">
                        {t('account_details')}
                    </Typography>
                </Grid>

                <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        direction: 'rtl',
                    }}
                >
                    <Grid container spacing={2} direction="row" alignItems="flex-start">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('id')}
                            </Typography>
                            <TextField
                                value={accountData.id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('phone_number')}
                            </Typography>
                            <TextField
                                value={accountData.phone_number}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('guid')}
                            </Typography>
                            <TextField
                                value={accountData.guid}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('auth')}
                            </Typography>
                            <TextField
                                value={accountData.auth}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('account_name')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="account_name"
                                value={accountData.account_name}
                                onChange={handleChange}
                                sx={{ textAlign: 'right' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('is_active')}
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="is_active"
                                    value={accountData.is_active}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={1}>{t('active')}</MenuItem>
                                    <MenuItem value={0}>{t('not_active')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" mt={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            sx={{
                                width: '200px', height: '45px',
                                borderRadius: 2,
                                boxShadow: 2,
                                textTransform: 'none',
                                ':hover': {boxShadow: 4}
                            }}
                        >
                            {isSubmitting ? t('is_updating') : t('submit_changes')}
                        </Button>
                    </Grid>

                    {isSubmitting && (
                        <>
                            <LoadingComponent />
                        </>
                    )}
                </Box>
            </Paper>
        </>
    );
};

export default UpdateAccountPage;
