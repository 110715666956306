import {getApiUrl, getQueryString} from "../../utils/config";
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Visibility, Delete } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Divider, CircularProgress, Typography, Tooltip, IconButton, Grid, Button, TextField, Box, Pagination
} from '@mui/material';

const IndexGroupMessagesPage = () => {
    const { id } = useParams();
    const [messagesData, setMessagesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
        message_id: '',
        id: '',
        receiver_type: 'group',
        receiver_id: id
    });
    const [page, setPage] = useState(1); // State for current page
    const [totalPages, setTotalPages] = useState(1); // State for total pages
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    const fetchMessages = async (filterParams = {}, currentPage = 1) => {
        setLoading(true);

        try {
            const queryString = getQueryString( filterParams, currentPage );

            const response = await ( await fetch( `${getApiUrl()}/api/messages/?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            setMessagesData(response.data);
            setTotalPages(response.summary.total_pages); // Assuming API sends total pages
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMessages(filters, page);
    }, [id, page]);

    const handleDelete = async (messageId) => {
        if (window.confirm(t('confirm_delete'))) {
            try {
                await fetch( `${getApiUrl()}/api/messages/${messageId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                } );

                fetchMessages(filters, page); // Re-fetch after delete
            } catch (err) {
                setError(`Failed to delete message: ${err.message}`);
            }
        }
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleApplyFilters = () => {
        setPage(1); // Reset to first page on filter apply
        fetchMessages(filters, 1);
    };

    const handlePageChange = (event, value) => {
        setPage(value); // Update current page
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{t('error')}: {error}</Typography>;
    }

    return (
        <div>
            {/* Header Section */}
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                <Typography variant="h5" fontWeight="bold">
                    {t('group_messages')}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/groups/${id}/messages/add`)}
                    sx={{ height: '40px', width: '200px' }}
                >
                    {t('add_new_message')}
                </Button>
            </Grid>

            <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />  {/* Gray line and margin below */}

            {/* Filters */}
            <Grid container alignItems="center" spacing={2} style={{ marginBottom: '16px' }} dir="rtl">
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('id')}
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('message_id')}
                        name="message_id"
                        value={filters.message_id}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={handleApplyFilters}
                        sx={{
                            height: '40px',
                            width: '200px',
                            backgroundColor: 'gray',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'darkgray'
                            }
                        }}
                    >
                        {t('apply_filters')}
                    </Button>
                </Grid>
            </Grid>

            {/* Table Section */}
            <TableContainer component={Paper} dir="rtl" sx={{ border: '1px solid lightgray' }}>
                <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '16.66%', fontWeight: 'bold' }} align="center">{t('id')}</TableCell>
                            <TableCell sx={{ width: '16.66%', fontWeight: 'bold' }} align="center">{t('message_id')}</TableCell>
                            <TableCell sx={{ width: '16.66%', fontWeight: 'bold' }} align="center">{t('receiver_type')}</TableCell>
                            <TableCell sx={{ width: '16.66%', fontWeight: 'bold' }} align="center">{t('text')}</TableCell>
                            <TableCell sx={{ width: '16.7%', fontWeight: 'bold' }} align="center">{t('operation')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messagesData.map((message) => (
                            <TableRow key={message.id}>
                                <TableCell align="center">{message.id}</TableCell>
                                <TableCell align="center">{message.message_id}</TableCell>
                                <TableCell align="center">{t(message.receiver_type)}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title={message.text} placement="top">
                                        <Typography noWrap>
                                            {message.text.length > 50 ? `${message.text.slice(0, 50)}...` : message.text}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton aria-label="edit" color="primary" size="small" onClick={() => navigate(`/messages/${message.id}/edit`)}>
                                        <Visibility fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="error" size="small" onClick={() => handleDelete(message.id)}>
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} dir="rtl">
                {/* Go to page on the left */}
                <TextField
                    label={t('go_to_page')}
                    type="number"
                    value={page}
                    onChange={(e) => setPage(Number(e.target.value))}
                    InputProps={{ inputProps: { min: 1, max: totalPages } }}
                    sx={{ width: '100px' }}
                />

                {/* Pagination at the center */}
                <Box flexGrow={1} display="flex" justifyContent="center">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            </Box>
        </div>
    );
};

export default IndexGroupMessagesPage;
