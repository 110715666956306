import { useNavigate } from 'react-router-dom';
import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Paper, Typography, Grid, Button, TextField, Dialog, DialogTitle, DialogContent,
    DialogActions, Divider, DialogContentText, FormControl, Select, MenuItem, Box }
from '@mui/material';

const StoreAccountPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( false );
    const [ accountId, setAccountId ] = useState( null );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );
    const [ formData, setFormData ] = useState( {
        account_name: '',
        phone_number: '',
        is_active: 1,
    } );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
        navigate( `/accounts/${accountId}` );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( '/accounts/add' );
    };

    const handleChange = ( event ) => {
        const { name, value, type, checked } = event.target;

        setFormData((prevData) => ( {
            ...prevData,
            [ name ]: type === 'checkbox' ? checked : value,
        } ) );
    };

    const handleSubmit = async ( event ) => {
        event.preventDefault();
        setLoading( true );

        try {
            const response = await ( await fetch(`${getApiUrl()}/api/accounts`, {
                method: 'POST',
                body: JSON.stringify( formData ),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            } ) ).json();

            setTimeout( () => {
                setAccountId( response.data.id );
                setLoading( false );
                setIsSuccessModalOpen( true );
            }, 1000 );
        }
        catch ( err ) {
            setError( `${t('create_account_failed')}: ${err.message}` );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
    };

    return (
        <>
            {/* Loading State */}
            {loading && (
                <>
                    <LoadingComponent />
                </>
            )}

            {/* Error Modal */}
            <Dialog
                open={isErrorModalOpen}
                onClose={handleCloseErrorModal}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{
                    style: { padding: '20px', borderRadius: '12px' },
                }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('error')}
                </DialogTitle>

                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {error ? error : t('unknown_error')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseErrorModal}
                        color="primary"
                        variant="contained"
                        style={{
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Modal */}
            <Dialog
                open={isSuccessModalOpen}
                onClose={handleCloseSuccessModal}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{
                    style: { padding: '20px', borderRadius: '12px' },
                }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('operation_result')}
                </DialogTitle>

                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('account_created_successfully')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseSuccessModal}
                        color="primary"
                        variant="contained"
                        style={{
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Main Content */}
            <Paper sx={{p: 3}} dir="rtl">
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                    <Typography variant="h5" fontWeight="bold">
                        {t('add_new_account')}
                    </Typography>
                </Grid>

                <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        direction: 'rtl',
                    }}
                >
                    <Grid container spacing={2} direction="row" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('account_name')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="account_name"
                                value={formData.account_name}
                                onChange={handleChange}
                                required
                                placeholder={t('account_name_sample')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('phone_number')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleChange}
                                required
                                placeholder={t('phone_number_sample')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('is_active')}
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="is_active"
                                    value={formData.is_active}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={1}>{t('active')}</MenuItem>
                                    <MenuItem value={0}>{t('not_active')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* Submit Button */}
                    <Grid container justifyContent="flex-end" mt={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                width: '200px', height: '45px',
                                borderRadius: 2,
                                boxShadow: 2,
                                textTransform: 'none',
                                ':hover': {boxShadow: 4}
                            }}
                        >
                            {t('submit')}
                        </Button>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
};

export default StoreAccountPage;
