import { useNavigate } from 'react-router-dom';
import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
    Paper, Typography, Grid, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox,
    List, ListItem, ListItemIcon, ListItemText, Pagination, CircularProgress, Box, MenuItem, Select, FormControl, Divider
} from '@mui/material';

const StoreBatchMessagesPage = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const { t, i18n } = useTranslation();
    const [error, setError] = useState(null);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [isGroupsModalOpen, setIsGroupsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        groups_selection_type: 'all',
        selected_groups: [],
        message: {
            text: '',
        }
    });

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    const fetchGroups = async (currentPage = 1) => {
        setLoading(true);
        try {
            const response = await ( await fetch( `${getApiUrl()}/api/groups/?page=${currentPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            setGroups(response.data);
            setTotalPages(response.summary.total_pages);
        }
        catch (err) {
            setError(err.message);
        }
        finally {
            setLoading(false);
        }
    };

    const openGroupsModal = () => {
        setIsGroupsModalOpen(true);
        fetchGroups(page);
    };

    const handleSelectedGroupsChange = (event) => {
        const value = event.target.value;
        setFormData({ ...formData, groups_selection_type: value });

        if (value === 'manual') {
            openGroupsModal();
        }
    };

    const handleDropdownClick = (event) => {
        const value = event.target.dataset.value;

        if (value === 'manual') {
            openGroupsModal();
        }
    };

    const handleSelectGroup = (groupId) => {
        setFormData((prevState) => {
            const selected_groups = prevState.selected_groups.includes(groupId)
                ? prevState.selected_groups.filter((id) => id !== groupId)
                : [...prevState.selected_groups, groupId];
            return { ...prevState, selected_groups };
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchGroups(value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await fetch(`${getApiUrl()}/api/messages/batch`, {
                method: 'POST',
                body: JSON.stringify( formData ),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            } );

            navigate('/groups');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name.startsWith('message.')) {
            const key = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                message: {
                    ...prevData.message,
                    [key]: type === 'checkbox' ? checked : value,
                },
            }));
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    return (
        <>
            {/* Header Section */}
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                <Typography variant="h5" fontWeight="bold">
                    {t('add_batch_messages')}
                </Typography>
            </Grid>

            <Divider sx={{ backgroundColor: 'lightgray' }} />

            {/* Main Content */}
            <Paper sx={{ padding: 4, width: '100%', mt: 4, border: '1px solid lightgray' }} dir="rtl">

                {/* Group Settings Section */}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {t('group_settings')}
                </Typography>
                <Grid container spacing={3} mb={4}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('select_group')}</Typography>
                        <FormControl fullWidth>
                            <Select
                                value={formData.groups_selection_type}
                                onChange={handleSelectedGroupsChange}
                                onClick={handleDropdownClick}
                                displayEmpty
                            >
                                <MenuItem value="all">{t('all_groups')}</MenuItem>
                                <MenuItem value="manual">{t('manual_group_selection')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Message Settings Section */}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {t('message_settings')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('text')}</Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="message.text"
                            value={formData.message.text}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>

                {/* Submit Button */}
                <Grid container justifyContent="flex-end" mt={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                            width: '200px', height: '45px',
                            borderRadius: 2,
                            boxShadow: 2,
                            textTransform: 'none',
                            ':hover': { boxShadow: 4 }
                        }}
                    >
                        {t('submit')}
                    </Button>
                </Grid>
            </Paper>

            {/* Group Selection Modal */}
            <Dialog open={isGroupsModalOpen} onClose={() => setIsGroupsModalOpen(false)} fullWidth maxWidth="md">
                <DialogTitle dir="rtl">{t('select_groups')}</DialogTitle>
                <DialogContent dividers>
                    {loading ? (
                        <Box display="flex" justifyContent="center" my={4}>
                            <CircularProgress />
                        </Box>
                    ) : error ? (
                        <Typography color="error" align="center" my={4}>
                            {t('error')}: {error}
                        </Typography>
                    ) : (
                        <List>
                            {groups.map((group) => (
                                <ListItem key={group.id} button onClick={() => handleSelectGroup(group.id)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={formData.selected_groups.includes(group.id)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`ID: ${group.id} - ${group.title || 'No Name'} (${group.account_id})`}
                                        secondary={`Status: ${group.is_active ? 'Active' : 'Inactive'}`}
                                        dir="rtl"
                                        style={{ textAlign: 'right' }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsGroupsModalOpen(false)}
                        size="large"
                        color="secondary"
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: 'secondary.main',
                            },
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StoreBatchMessagesPage;
